// Lib
import {
    emailFormat,
    minLength,
    onlyNumbers,
    regex,
    required,
    validKatakana,
    validNormalPhone,
    validPhoneNumber,
    validZipCode,
    validPasswordWorker
} from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createWorkerValidation = (t: TypeOfT) => ({
    staff_code: () => ({
        required: required('worker_code', t),
    }),
    name: () => ({
        required: required('worker_name', t),
    }),
    kana: () => ({
        required: required('worker_kana', t),
        minLength: minLength('worker_kana', 2, t),
        validate: {
            validKatakana: validKatakana('worker_kana', t),
        },
    }),
    bank_kana: () => ({
        validate: {
            validKatakana: validKatakana('bank_kana', t),
        },
    }),
    gender_type: () => ({
        required: required('worker_gender_type', t),
    }),
    cooperative_company_id: () => ({
        required: required('cooperative_company_id', t),
    }),
    department_id: () => ({
        required: required('department_id', t),
    }),
    position_id: () => ({
        required: required('position_id', t),
    }),
    celphone: () => ({
        required: required('celphone', t),
        validate: {
            onlyNumbers: onlyNumbers('celphone', t),
        },
    }),
    fax: () => ({
        required: required('fax', t),
        // validate: {
        //     onlyNumbers: onlyNumbers('celphone', t),
        // },
    }),
    email: () => ({
        // required: required('email', t),
        validate: {
            emailFormat: emailFormat('email', t),
        },
    }),
    phone: () => ({
        // required: required('phone', t),
        validate: {
            onlyNumbers: onlyNumbers('phone', t),
        },
    }),
    zipcode: () => ({
        // required: required('zipcode', t),
        validate: {
            regex: validZipCode('zipcode', t),
        },
    }),
    password: () => ({
        required: required('password', t),
        validate: {
            regex: /^(?=.[A-Za-z])(?=.\d)(?=.[!@#$%^&]).{6,24}$/i,
            message: 'invalid password'
        },
    }),
    birthday: () => ({
        required: required('birthday', t),
    }),
    address: () => ({
        required: required('address', t),
    }),
});

export default createWorkerValidation;
