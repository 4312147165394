import { message, UploadProps } from 'antd';
import { useState, useEffect, FocusEventHandler, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { alertSuccess, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { convertErrorMessagesToObject, validateFullWhitespace } from 'utils/validation/validatorHelper';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertClientCompaniesToDropdownOptionsCreateProject } from 'utils/helper/clientCompanyHelper';
import positionService from '../../../services/positionService';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import workersService from 'services/wokersService';
import isEmpty from 'lodash/isEmpty';
import userService from 'services/userService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import departmentsService from 'services/departmentsService';

export type ReceivedProps = {
    screenType: "create" | "edit" | "detail";
};
const useWorkerForm = (props: ReceivedProps) => {
    const { screenType } = props;
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const id = useParams();
    const navigate = useNavigate();
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [isLoading, setIsLoading] = useState(false);
    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );
    const { departmentList } = useSelector(
        (state: RootState) => state.deparments,
    );
    const [companyOption, setCompanyOption] = useState<any>([]);
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const [indexError, setIndexError] = useState(0);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    const [positionOption, setPositionOption] = useState<any>([]);
    const [departmentOption, setDepartmentOption] = useState<any>([]);
    const [isAllowUpload, setAllowUpload] = useState(true);
    const [fileLength, setFileLength] = useState<number>(0);

    const [listGenderValue, setListGenderValue] = useState<any>(null);

    const [deleteError, setDeleteError] = useState<any>(null)
    const [fileList, setFileList] = useState<any>([])
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [totalImageSize, setTotalImageSize] = useState<number>(0);
    const [listFile, setListFile]: any = useState([]);
    const UPLOAD_LIMIT = 50;

    const dragProps: UploadProps = {
        name: "file",
        onChange(info) {
            console.log("onChange info", info);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };
    const dummyRequest: UploadProps["customRequest"] = async ({
        file,
        onSuccess
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess("ok");
            }
        }, 0);
    };


    const handleClose = () => {
        clearErrors();
        setTotalImageSize(0);
        navigate('/quote/list');
    };

    const submitData = async (value: any, evt: any) => {
        // evt.preventDefault()
        if (value?.zipCode) {
            const isZipcodeFormat = validateZipcode(value?.zipCode);
            if (!isZipcodeFormat) {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }

        if (value?.zipCodeConstruction) {
            const isZipcodeFormat = validateZipcode(value?.zipCodeConstruction);
            if (!isZipcodeFormat) {
                setError('zipCodeConstruction', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }
        setIsLoading(true);
        let formData = new FormData();
        formData.append('title', '');
        for (let i = 0; i < value?.images.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }

        formData.append('note', value?.note || '');
        formData.append('status', value?.status);
        formData.append('quote_type', value?.quote_type);
        formData.append('desired_date', value?.dateTime);
        formData.append('zipcode', value?.zipCodeConstruction);
        formData.append('address', value?.construction_address);
        formData.append('content', value?.content);
        formData.append('project_title', value?.project_title);
        formData.append('customer[first_name]', value?.first_name);
        formData.append('customer[last_name]', value?.last_name);
        formData.append('customer[first_name_katakana]', value?.first_name_kana);
        formData.append('customer[last_name_katakana]', value?.last_name_kana);
        formData.append('customer[address]', value?.address);
        formData.append('customer[zipcode]', value?.zipCode);
        formData.append('customer[phone_number]', value?.phone);
        try {
            if (screenType === "create") {

            } else {

            }
            handleClose();
        } catch (error) {
            setIsLoading(false);
        }
    };



    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };
    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setListGenderValue(
                    convertUserConfigToDropdownOptions(
                        response.configurations.worker.gender_types,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListDemartment = async () => {
        try {
            const response = await departmentsService.getListDepartments();
            if (response !== null && response !== undefined) {
                setDepartmentOption(
                    convertObjectToDropdownOptions(response.departments),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    }

    const handleRemove = (file) => {
        setValue('signature_stamp', '')
        setError('signature_stamp', {
            type: 'validate',
            message: '',
        })
    }

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
            listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterReMove: any =
            loadFile < filteredPdfAfterRedundant?.length &&
            filteredPdfAfterRedundant?.slice(0, loadFile > 1 ? loadFile : 1);

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const getListCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            if (response !== null && response !== undefined) {
                setCompanyOption(
                    convertClientCompaniesToDropdownOptionsCreateProject(
                        response.cooperative_companies,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListPositions = async () => {
        try {
            const response = await positionService.getListPositions();
            if (response !== null && response !== undefined) {
                setPositionOption(
                    convertObjectToDropdownOptions(response.positions),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const parsePostalCode = (zipCode: string) => {
        if (!zipCode) {
            setError('zipCode', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);

        if (!isZipcodeFormat) {
            setError('zipCode', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');

        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address', foundAddress);
                clearErrors('address');
                clearErrors('zipCode');
            } else {
                setValue('address', '');
                setError('zipCode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    const handleCreateWorker = async (data) => {
        return new Promise(async (resolve, reject) => {
            await workersService
                .createWorker(data)
                .then(() => {
                    navigate('/workers');
                })
                .catch((err) => {
                    const response = err?.response;
                    if (!response || typeof response === 'undefined') {
                        window.location.href = '/login';
                        reject(err);
                    }
                    switch (response.status) {
                        case 403:
                            const messages = convertErrorMessagesToObject(
                                {
                                    gender_type: 'gender_type',
                                },
                                response.data?.error,
                            );

                            if (!isEmpty(messages)) {
                                Object.keys(messages).map((key) =>
                                    setError(key, { message: messages[key] }),
                                );
                            } else {
                                setError('name', {
                                    message: response.data?.error,
                                });
                            }
                            break;
                        default:
                    }
                    reject(err);
                });
            resolve(true);
        });
    };

    useEffect(() => {
        getListCompanies();
        getListConfiguration();
        if (positionsList !== undefined && positionsList.length == 0)
            getListPositions();
        else {
            setPositionOption(convertObjectToDropdownOptions(positionsList));
        }
        if (departmentList !== undefined && departmentList.length == 0)
            getListDemartment();
        else {
            setDepartmentOption(convertObjectToDropdownOptions(departmentList));
        }
    }, []);

    return {
        ...props,
        handleSubmit,
        submitData,
        control,
        dragProps,
        dummyRequest,
        setValue,
        register,
        clearErrors,
        watch,
        getValues,
        setError,
        errors,
        navigate,
        fileLength,
        isAllowUpload,
        listFile,
        setFileLength,
        setAllowUpload,
        setListFile,
        totalImageSize,
        setTotalImageSize,
        handleValidateWhitespace,
        screenType,
        positionOption,
        indexError,
        departmentOption,
        sizeUploadLimitError,
        listGenderValue,
        deleteError,
        fileList,
        previewOpen,
        previewImage,
        previewTitle,
        handleRemove,
        handleChangeFile,
        handleBeforeUpload,
        parsePostalCode,
        handleCreateWorker,
        handlePreview
    };
};

export type Props = ReturnType<typeof useWorkerForm>;

export default useWorkerForm;
