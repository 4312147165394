import React, { useState, useEffect } from 'react';
import './header.css';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/user/user';
import { RootState, AppDispatch, useAppDispatch } from '../../store/index';
import Logout from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { logout } from '../../store/auth/auth';
import { useLocation } from 'react-router-dom';

declare let window: any;

const Header = (props) => {
    const { isChangeTab } = props;
    const [hide, setHide] = useState(false);
    const [title, setTitle] = useState('');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const dispatch = useAppDispatch();

    const { userData } = useSelector((state: RootState) => state.users);

    const getMe = async () => {
        const response = dispatch(getUser());
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const location = useLocation();
    useEffect(() => {
        getMe();
        let path = window.location.pathname;
        if (path == '/login') {
            setHide(true);
        }
        let pathTemp = window.location.pathname;
        if (pathTemp == '/') {
            setTitle('見積依頼管理');
        }
        if (pathTemp == '/users') {
            setTitle('アカウント');
        }
        if (pathTemp == '/bills') {
            setTitle('請求');
        }
        if (pathTemp == '/estimates') {
            setTitle('見積');
        }
        if (pathTemp == '/budgets') {
            setTitle('予実管理');
        }
        if (pathTemp == '/departments') {
            setTitle('部署');
        }
        if (pathTemp == '/positions') {
            setTitle('役職');
        }
        if (pathTemp == '/internal-ojt') {
            setTitle('各種マニュアル');
        }
        if (pathTemp == '/vehicle-inspection') {
            setTitle('車両点検簿');
        }
        if (pathTemp == '/working_times') {
            setTitle('勤務時間');
        }
        if (pathTemp == '/working_times_management') {
            setTitle('勤怠管理');
        }
        if (pathTemp == '/meeting-minutes') {
            setTitle('議事録');
        }
        if (
            path.includes('/workers')

        ) {
            setTitle('社員一覧');
            if (
                path.includes('/workers/create')
            ) {
                setTitle('新規作成');
            }
            else if (path.includes('/workers/detail') || path.includes('/workers/edit')) {
                setTitle('情報編集');
            }
        }
        if (pathTemp == '/projects/create') {
            setTitle('ダッシュボード');
        }
        if (
            pathTemp == '/projects' ||
            path.includes('/projects/detail') ||
            path.includes('/projects/estimation')
        ) {
            setTitle('案件');
        }
        if (
            path === '/companies' ||
            path === '/companies/detail' ||
            path === '/companies/create' ||
            path === '/companies/edit'
        ) {
            setTitle('顧客会社');
        }
        if (
            path === '/cooperative-companies' ||
            path === '/cooperative-companies/detail' ||
            path === '/cooperative-companies/create' ||
            path === '/cooperative-companies/edit'
        ) {
            setTitle('協力会社');
        }
        if (path === '/calendar') {
            setTitle('カレンダー');
        }
        if (
            [
                '/suggestions',
                '/suggestions/create',
                '/suggestion/detail',
            ].includes(pathTemp)
        ) {
            setTitle('目安箱');
        }
        if (['/news'].includes(pathTemp)) {
            setTitle('案件');
        }
        if (['/setting'].includes(path)) {
            setTitle('設定');
        }
        if (pathTemp == '/chats') {
            setTitle('チャットルーム');
        }
        if (pathTemp == '/stock_notes') {
            setTitle('納品書一覧');
        }
        if (pathTemp == '/inspection-records') {
            setTitle('車両系建設機械始業前点検表');
        }
        if (pathTemp.includes('/quote')) {
            if (pathTemp.includes('/quote/list/invoice')) {
                setTitle('請求書発行');
            } else if (pathTemp.includes('/quote/list')) {
                setTitle('見積依頼管理');
            } else if (pathTemp.includes('/quote/create')) {
                setTitle('見積新規作成');
            } else if (pathTemp.includes('/quote/edit')) {
                setTitle('見積編集');
            } else {
                setTitle('見積詳細')
            }
        } if (pathTemp.includes('/quote/list/invoice')) {
            setTitle('請求書発行');
        }

        if (pathTemp === '/company-info-setting') {
            setTitle('設定');
        }
        if (pathTemp === '/invoices') {
            setTitle('請求管理');
        }
    }, [isChangeTab, location]);

    return (
        <div
            className="header"
            style={{
                display: hide ? 'none' : '',
            }}
        >
            <div className="header-left">{title}</div>
            <div className="header-right d-flex flex-row">
                <div className="line"></div>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            fontFamily: 'Zen Kaku Gothic Antique',
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        ログアウト
                    </MenuItem>
                </Menu>
                <div
                    className="username"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {userData !== null && userData!.email}
                </div>
            </div>
        </div>
    );
};
export default Header;
