import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { InvoiceStatusEnum, QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import useInvoiceHome, { Props, ReceivedProps } from './hook';
import { Button, ConfigProvider, DatePicker, Input, Select, Tabs, TabsProps } from 'antd';
import jaJP from 'antd/es/locale/ja_JP';
import InvoiceList, { fullWidthNumConvert, parseNamePdf } from 'pages/invoice';
import InvoiceBudget from './invoiceBudged';
import InvoiceActual from './invoiceActual';
import querystring from 'query-string'
import invoiceValidation from 'utils/validation/registers/invoice';
import moment from 'moment';
import { validZipCode } from 'utils/validation/registers/validator';
import { useTranslation } from 'react-i18next';
import { validateZipcode } from 'utils/helper/appHelper';
import { Box } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import UploadWhite from 'assets/icons/upload-white.svg'
import UploadGrey from 'assets/icons/upload-grey.svg'
import UpArrow from 'assets/icons/up-arrow.svg'
import RedTrash from 'assets/icons/Trash.svg';
import PlusIcon from 'assets/icons/white-plus.svg'
import { formatCurrency } from 'utils/helper/currency';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { NumericFormat } from 'react-number-format';
import { FormProvider } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { handleFilter } from 'utils/helper/filterDropdown';
import { InvoiceSelectStyle } from './style';

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));

statusOptions.unshift({
    value: 'all',
    name: 'すべて'
})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const typeOptions: SelectSearchOption[] = [
    { value: 'interior', name: '内装' },
    { value: 'exterior', name: '外装' },
    { value: 'water_area', name: '水回り' },
    { value: 'other', name: 'その他' },
];

const InvoiceHomeLayout: FC<Props> = ({
    onChange,
    curTab,
    setCurTab,
    handleSubmit,
    resetField,
    errors,
    handleValidateWhitespace,
    submitDataInvoice,
    Controller,
    control,
    watch,
    register,
    getValues,
    setError,
    setValue,
    clearErrors,
    queryObj,
    getDetail,
    formHandler,
    submitData
}) => {
    const [disableTab, setDisableTab] = useState(false)
    const navigate = useNavigate()
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <p className='w-[200px] mb-0 text-center' >見積</p>,
            children: <InvoiceList />,
        },
        {
            key: '2',
            label: <p className='w-[200px] mb-0 text-center' >仕入値予算</p>,
            children: <InvoiceBudget />,
            // disabled: disableTab
        },
        {
            key: '3',
            label: <p className='w-[200px] mb-0 text-center' >仕入値実績</p>,
            children: <InvoiceActual />,
            // disabled: disableTab
        },
    ];
    const findZipcodeTimer = useRef<NodeJS.Timeout>();

    const { t } = useTranslation();

    const parsePostalCode = (zipCode: string, type: string) => {
        if (!zipCode) {
            if (type === 'customer') {
                setError('zipCode', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            } else {
                setError('zipCodeConstruction', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            }

            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);
        if (!isZipcodeFormat) {
            setError('zipCode', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });

            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address2', foundAddress);
                clearErrors('address2');
                clearErrors('zipCode')

            } else {
                setValue('address2', '');
                setError('zipCode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    useEffect(() => {
        if (!queryObj?.quote_id && !queryObj?.invoice_id) {
            setDisableTab(true)
        } else {
            setDisableTab(false)
        }
    }, [])
    return (
        <ConfigProvider locale={jaJP}>
            <FormProvider {...formHandler}>
                <form onSubmit={handleSubmit(submitData)}>
                    <div className='bg-[#fff] mt-[15px] mx-[15px] px-[15px] py-[20px]' >
                        <p className='text-[16px] font-bold'>会社の情報</p>
                        <div className='grid grid-cols-4 w-[98%]' style={{ gap: '10px' }} >
                            <Controller
                                name="companyName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            会社名
                                        </p>

                                        <StyledInput placeholder='会社名'
                                            {...register(
                                                'companyName',
                                                invoiceValidation().company(),
                                            )}
                                            {...field}
                                            onBlur={handleValidateWhitespace}
                                        />
                                        <br />
                                        {errors.companyName && (
                                            <span className="error">
                                                {errors.companyName.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="address"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            住所
                                        </p>
                                        <StyledInput placeholder='住所'
                                            {...register(
                                                'address',
                                                invoiceValidation().address(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.address && (
                                            <span className="error">
                                                {errors.address.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="bankAccount"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            銀行口座
                                        </p>
                                        <StyledInput placeholder='銀行口座'
                                            {...register(
                                                'bankAccount',
                                                invoiceValidation().bankAccount(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.bankAccount && (
                                            <span className="error">
                                                {errors.bankAccount.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />
                            <Controller
                                name="paymentDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            振込期限
                                        </p>
                                        <DatePicker
                                            {...register(
                                                'paymentDate',
                                                invoiceValidation().paymentDate(),
                                            )} {...field} format={'YYYY年MM月DD日'}
                                            onBlur={handleValidateWhitespace}
                                            value={field.value ? moment(field.value) : null}
                                            placeholder='yyyy年mm月dd日' style={{ width: '100%', height: '40px' }} />
                                        <br />
                                        {errors.paymentDate && (
                                            <span className="error">
                                                {errors.paymentDate.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />
                        </div>

                        <p className='text-[16px] font-bold mt-[20px]'>お客様の情報</p>
                        <div className='grid grid-cols-4 w-[98%]' style={{ gap: '10px' }} >
                            <Controller
                                name="projectTitle"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            件名
                                        </p>
                                        <StyledInput  {...register(
                                            'projectTitle',
                                            invoiceValidation().projectTitle(),
                                        )} placeholder='件名' {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.projectTitle && (
                                            <span className="error">
                                                {errors.projectTitle.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />
                            <Controller
                                name="customer"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            お客様
                                        </p>
                                        <StyledInput  {...register(
                                            'customer',
                                            invoiceValidation().customer(),
                                        )} placeholder='お客様' {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.customer && (
                                            <span className="error">
                                                {errors.customer.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <div className="flex flex-col">
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register('zipCode', {
                                                required:
                                                    '郵便番号を入力してください。',
                                                validate: {
                                                    regex: validZipCode(
                                                        'zipCode',
                                                        t,
                                                    ),
                                                },
                                            });
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    工事郵便番号
                                                </p>
                                                <div className="flex gap-[10px]">
                                                    <Input
                                                        placeholder="000-0000"
                                                        {...register('zipCode', {
                                                            required:
                                                                '郵便番号を入力してください。',
                                                            validate: {
                                                                regex: validZipCode(
                                                                    'zipcode',
                                                                    t,
                                                                ),
                                                            },
                                                        })}
                                                        className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                        onBlur={(evt) => {
                                                            let string = evt.target.value.replaceAll('ー', '',);
                                                            string = string.replaceAll('－', '',);
                                                            string = string.replaceAll('−', '',);
                                                            string = string.replaceAll('-', '',);
                                                            string = string.replaceAll(/[a-zA-Zぁ-んァ-ン一-龥]/g, '',);
                                                            string = fullWidthNumConvert(string,);
                                                            if (string.length > 4 && string.indexOf('-',) !== 3) {
                                                                let txt2 = string.replaceAll('-', '',);
                                                                txt2 = string.slice(0, 3,) + '-' + string.slice(3,);
                                                                setValue('zipCode', txt2,);
                                                            } else {
                                                                setValue('zipCode', string,);
                                                            }
                                                        }}
                                                        maxLength={8}
                                                        value={field.value}
                                                        onChange={(evt) => {
                                                            field.onChange(
                                                                evt.target
                                                                    .value,
                                                            );
                                                            setValue(
                                                                'zipCode',
                                                                evt.target
                                                                    .value,
                                                            );
                                                            clearErrors(
                                                                'zipCode',
                                                            );
                                                        }}
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                fieldState.error
                                                            ) {
                                                                return;
                                                            }
                                                            parsePostalCode(
                                                                getValues(
                                                                    'zipCode',
                                                                ) as string, 'construction'
                                                            );
                                                        }}
                                                        type="button"
                                                        className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !min-w-[110px] !text-[16px] !p-0 !w-fit disabled:opacity-60"
                                                        style={{
                                                            height: '40px',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        住所自動入力
                                                    </button>
                                                </div>
                                                {errors.zipCode && (
                                                    <span className="error">
                                                        {errors.zipCode.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <Controller
                                name="address2"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            住所
                                        </p>
                                        <StyledInput {...register(
                                            'address2',
                                            invoiceValidation().address(),
                                        )} {...field} placeholder='住所'
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.address2 && (
                                            <span className="error">
                                                {errors.address2.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            請求日
                                        </p>
                                        <DatePicker
                                            {...register(
                                                'date',
                                                invoiceValidation().date(),
                                            )} {...field} format={'YYYY年MM月DD日'}
                                            onBlur={handleValidateWhitespace}
                                            value={field.value ? moment(field.value) : null}
                                            placeholder='yyyy年mm月dd日' style={{ width: '100%', height: '40px' }} />
                                        <br />
                                        {errors.date && (
                                            <span className="error">
                                                {errors.date.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />


                            <InvoiceSelectStyle className="flex flex-col w-full">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        見積種別
                                    </p>
                                </div>
                                <Controller
                                    name="quoteType"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <SelectSearch
                                            options={typeOptions}
                                            search
                                            filterOptions={handleFilter}
                                            {...register(
                                                'quoteType',
                                                invoiceValidation().quoteType(),
                                            )}
                                            {...field}
                                            emptyMessage={'該当なし'}
                                            placeholder=""
                                        />
                                    )}
                                />
                                {errors.quoteType && (
                                    <span className="error !text-[14px]">
                                        {errors.quoteType.message?.toString()}
                                    </span>
                                )}
                            </InvoiceSelectStyle>


                            <Controller
                                name="callNum"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            請求番号
                                        </p>
                                        <StyledInput placeholder='請求番号'
                                            style={{ background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                            {...register(
                                                'callNum',
                                                // invoiceValidation().callNum(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace}
                                            // disabled={queryObj?.quote_id ? true : false} 
                                            disabled
                                        />
                                        <br />
                                        {errors.callNum && (
                                            <span className="error">
                                                {errors.callNum.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                        </div>
                    </div>
                    <div className='bg-[#fff] mx-[15px] mt-[15px]'>
                        <Tabs activeKey={curTab} destroyInactiveTabPane items={items} onChange={onChange} />
                    </div>
                </form>
            </FormProvider>


        </ConfigProvider>
    );
};

const InvoiceHome: FC<ReceivedProps> = (props) => {
    return <InvoiceHomeLayout {...useInvoiceHome(props)} />;
};

const StyledInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    width: '100%'
})

const StyledSelect = styled(Select)({
    width: '100%'
})
const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px'
})

export default InvoiceHome;
